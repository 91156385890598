import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import colors from '../config/color';
import css from 'styled-jsx/css';

const gatsbyLink = css.resolve`
  a {
    color: ${colors['dirt-brown']};
    text-decoration: none;
    padding: 10px;
  }
  a:hover {
    color: #ffffff;
    text-decoration: none;
  }
`;

const gatsbyActiveLink = css.resolve`
  a {
    color: #fff;
  }
`;

const Pagination = props => {
  const { totalPage, activePage, baseUrl } = props;
  let startPage = 1;
  if (activePage < 3) {
    // 頁數小於 3 讓最左邊頁數等於 1
    startPage = 1;
  } else {
    // 頁數大於 3，讓 active page 置中
    startPage = activePage - 2;
  }
  let endPage = activePage + 2;
  if (activePage < 3) {
    //  頁數小於 3 讓最右邊頁數等於 5
    endPage = 5;
  }
  if (endPage > totalPage) {
    endPage = totalPage;
  }
  if (activePage > endPage - 2) {
    startPage = totalPage - 4;
  }
  if (startPage < 1) startPage = 1;
  if (endPage > totalPage) endPage = totalPage;

  let maxPage = endPage - startPage + 1;
  if (maxPage <= 1) return null;
  return (
    <ul className="page-ul">
      <li
        style={{
          visibility: activePage === 1 ? 'hidden' : 'visible',
        }}
        className="page-number page-action"
      >
        <Link
          className={`${gatsbyLink.className}`}
          to={`${baseUrl}/${activePage - 1}/`}
        >
          <FaAngleLeft />
        </Link>
      </li>
      {[...Array(maxPage).keys()].map(i => {
        const isActive = activePage === i + startPage;
        return (
          <li
            key={`page-${i}`}
            className={`page-number ${isActive ? 'active' : ''}`}
          >
            <Link
              className={`${gatsbyLink.className} ${
                isActive ? gatsbyActiveLink.className : ''
              }`}
              to={`${baseUrl}/${i + startPage}/`}
            >
              {i + startPage}
            </Link>
          </li>
        );
      })}
      <li
        style={{
          visibility: activePage === totalPage ? 'hidden' : 'visible',
        }}
        className="page-number page-action"
      >
        <Link
          className={`${gatsbyLink.className}`}
          to={`${baseUrl}/${activePage + 1}/`}
        >
          <FaAngleRight />
        </Link>
      </li>

      {gatsbyLink.styles}
      {gatsbyActiveLink.styles}
      <style jsx>{`
        .page-number {
          width: 18px;
          height: 18px;
          font-size: 14px;
          color: ${colors['dirt-brown']};
          border: solid 1px ${colors['dirt-brown']};
          border-radius: 1000px;
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px 0 10px;
        }

        .page-number:hover {
          background-color: ${colors['dirt-brown-70%']};
          border-color: rgba(86, 63, 46, 0.1);
          cursor: pointer;
        }

        .page-ul {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          width: 30%;
          margin: auto;
        }

        .active {
          color: #ffffff;
          background-color: ${colors['dirt-brown']};
        }

        .page-action {
          font-size: 20px;
          border: solid 1px #ffffff;
        }
      `}</style>
    </ul>
  );
};

Pagination.propTypes = {
  startPage: PropTypes.number,
  endPage: PropTypes.number,
  totalPage: PropTypes.number,
  activePage: PropTypes.number,
  baseUrl: PropTypes.string,
};

Pagination.defaultProps = {
  startPage: 0,
  endPage: 0,
  totalPage: 0,
  activePage: '',
  baseUrl: '',
};

export default Pagination;
